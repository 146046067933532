<template>
  <div id="betting-pool-layout">
    <v-system-bar
      height="30"
      lights-out
      dark>
      <v-spacer />
      <v-btn
        icon
        class="sign-out"
        @click="logout">
        <font-awesome-icon icon="sign-out-alt" />
      </v-btn>
    </v-system-bar>
    <v-slide-x-transition mode="out-in">
      <router-view @logout="logout" />
    </v-slide-x-transition>
  </div>
</template>

<script>
import { LOGOUT_USER } from "@/store/actions.js";
import { RENEW_TOKEN } from "@/store/mutations.js";
import HttpStatus from "http-status";
import { attachResponseInterceptor, detachResponseInterceptor } from "@/api/httpClient.js";
import { checkRouletteSession } from "@/api/session.js";
import jwtDecode from "jwt-decode";
import CLOCK_MODULE, { MODULE_NAME as CLOCK_MODULE_NAME } from "@/store/modules/clock";
import { SET_TIME, SET_TIME_ZONE } from "@/store/modules/clock/mutations.js";
import moment from "moment-timezone";
import { START_CLOCK, STOP_CLOCK } from "@/store/modules/clock/actions.js";
import { mapState } from "vuex";

export default {
  name: "RouletteBoardLayout",
  async beforeRouteEnter(to, from, next) {
    if (from.path === "/") {
      try {
        await checkRouletteSession();
        next();
      } catch (e) {
        next(vm => {
          vm.logout();
        });
      }
    } else {
      next();
    }
  },
  data() {
    return {
      axiosInterceptorIdentifier: null,
    };
  },
  computed: {
    ...mapState({
      token: state => state.token,
      sessionExpiration: state => state.session.exp,
    }),
  },
  created() {
    const {
        serverTime,
        timeZone,
      } = jwtDecode(this.token).data,
      parsedDate = moment(serverTime).
        tz(timeZone);

    this.$store.registerModule(CLOCK_MODULE_NAME, CLOCK_MODULE);
    this.$store.commit(`${CLOCK_MODULE_NAME}/${SET_TIME_ZONE}`, timeZone);
    this.$store.dispatch(`${CLOCK_MODULE_NAME}/${START_CLOCK}`, parsedDate);
    this.$watch(`$store.state.${CLOCK_MODULE_NAME}.time`, currentTime => {
      const expirationTime = moment(this.sessionExpiration * 1000);

      if (currentTime.isAfter(expirationTime)) {
        this.logout();
      }
    });

    if (!this.$cable.connected) {
      this.connectWebsocket({ token: `Bearer ${this.token}` });
    }

    this.axiosInterceptorIdentifier = attachResponseInterceptor(
      (response, token) => {
        if (token) {
          this.$store.commit(RENEW_TOKEN, { token: token });
          const decodedToken = jwtDecode(token),
            {
              serverTime,
              timeZone,
            } = decodedToken.data;
          this.$store.commit(RENEW_TOKEN, {
            token,
            exp: decodedToken.exp,
          });
          this.$store.commit(`${CLOCK_MODULE_NAME}/${SET_TIME}`, moment(serverTime).
            tz(timeZone));
          this.$store.commit(`${CLOCK_MODULE_NAME}/${SET_TIME_ZONE}`, timeZone);
        }
      },
      error => {
        if (error.response) {
          const { status } = error.response;

          if (status === HttpStatus.NOT_FOUND) {
            console.warn(
              `API Endpoint with url: '${error.config.url}' not found.`,
            );
          } else if (status === HttpStatus.BAD_REQUEST) {
            console.error(error);
          } else if (status === HttpStatus.UNAUTHORIZED) {
            this.logout();
          } else if (status === HttpStatus.INTERNAL_SERVER_ERROR) {
            console.error(error);
          }
        }
        return Promise.reject(error);
      },
    );
  },
  destroyed() {
    this.disconnectWebsocket();
    detachResponseInterceptor(this.axiosInterceptorIdentifier);
    this.$store.dispatch(`${CLOCK_MODULE_NAME}/${STOP_CLOCK}`);
    this.$store.unregisterModule(CLOCK_MODULE_NAME);
  },
  methods: {
    logout() {
      this.$store.dispatch(LOGOUT_USER).
        then(() => {
          this.$router.replace({
            name: this.$routes.session.rouletteRoute.name,
            query: { isFirestick: true },
          });
        });
    },
  },
};
</script>

<style scoped>
#betting-pool-layout {
  background: url("../../../public/img/backgrounds/wood.jpeg");
  height: 100%;
  overflow: hidden;
}

.sign-out {
  z-index: 10;
}
</style>
